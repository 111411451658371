<template>
  <div>
    <v-dialog v-model="show" min-width="850px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ selectedCity.name }}</span>
          <v-spacer></v-spacer>
          <download-excel
            :fields="{
              الاسم: 'name',
              المحافظة: 'governorate.name',
              العنوان: 'location',
              البريد_الالكتروني: 'email',
            }"
            :name="selectedCity.name + '- شركات القطاع العام'"
            :data="items"
          >
            <v-btn icon color="primary">
              <v-icon>mdi-file-download</v-icon>
            </v-btn>
          </download-excel>

          <v-btn icon color="primary" @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          class="pb-2"
          :headers="headers"
          :items="items"
          hide-default-footer
        >
          <template v-slot:[`item.sub_sectors`]="{ item }">
            <p v-for="row in item.sub_sectors" :key="row.index">
              {{ row.name }}
            </p>
          </template>
          <template v-slot:[`item.logo`]="{}">
            <v-img
              src="~@/assets/gdid_logo.png"
              max-width="140"
              class="mx-auto"
            ></v-img>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["visible", "selectedCity", "selectedCityProjects"],
  components: {},
  data() {
    return {
      headers: [
        {
          text: "الاسم",
          align: "center",
          sortable: false,
          value: "name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "الشركات المدمجة",
          align: "center",
          sortable: false,
          value: "sub_sectors",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المحافظة",
          align: "center",
          sortable: false,
          value: "governorate.name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "العنوان",
          align: "center",
          sortable: false,
          value: "location",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "البريد الالكتروني",
          align: "center",
          sortable: false,
          value: "email",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "شعار الشركة",
          align: "center",
          sortable: false,
          value: "logo",
          class: "primary white--text ",
          width: 150,
        },
      ],
      items: this.selectedCityProjects,
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    show: function () {
      this.items = this.selectedCityProjects;
      console.log(this.items);
    },
  },
  methods: {},
};
</script>
