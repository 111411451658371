var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"append-icon":"mdi-magnify","label":"بحث","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('download-excel',{attrs:{"fields":{
          رقم_الاجازة: 'license_num',
          اسم_المشروع: 'project_name',
          المنطوق: 'foundation_license_type',
          عدد_الطاقة_الانتاجية: 'production_capacity',
          وحدة_القياس: 'production_unit',
          المادة_المنتجة: 'material',
          حالة_المشروع: 'status',
        },"data":_vm.selectedProjectList,"name":_vm.text}},[_c('v-btn',{attrs:{"icon":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-file-download")])],1)],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-data-table',{staticClass:"pb-2",attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.selectedProjectList,"search":_vm.search,"disable-sort":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }