<template>
  <v-dialog v-model="show" max-width="700px">
    <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark class="pa-4">
        <v-card-text>
          يرجى الانتظار...
          <v-progress-linear
            indeterminate
            color="accent"
            class="mt-4"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card>
      <v-card-title class="justify-center">
        <div class="text-h5 text-center">أضافة مشروع قطاع عام</div>
        <v-spacer></v-spacer>
        <v-btn icon color="primary" @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-actions>
        <v-tabs color="primary primary-4" left>
          <v-tab>الشركة بعد الدمج</v-tab>
          <v-tab-item>
            <v-container>
              <v-form ref="formPublic" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      required
                      outlined
                      v-model="formPublic.name"
                      :rules="formRule"
                      label="الاسم"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      return-object
                      :items="allCities"
                      item-text="name"
                      label="المحافظة"
                      outlined
                      :rules="formRule"
                      v-model="formPublic.city"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      required
                      outlined
                      v-model="formPublic.location"
                      :rules="formRule"
                      label="العنوان"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      required
                      outlined
                      v-model="formPublic.email"
                      :rules="formRule"
                      label="البريد الالكتروني"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      required
                      outlined
                      v-model="formPublic.logo"
                      :rules="formRule"
                      label="شعار الشركة"
                      accept=".png, .jpg, .jpeg"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      elevation="2"
                      width="150px"
                      large
                      color="primary"
                      @click="AddPublicSector"
                    >
                      اضافة
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-tab-item>
          <v-tab>اضافة شركة مدمجة</v-tab>
          <v-tab-item>
            <v-container>
              <v-form ref="formSub" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      required
                      outlined
                      v-model="formSub.name"
                      :rules="formRule"
                      label="اسم الشركة المدمجة"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      return-object
                      :items="allPublicSectors"
                      item-text="name"
                      label="اسم الشركة بعد الدمج"
                      outlined
                      :rules="formRule"
                      v-model="formSub.public_sector"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      elevation="2"
                      width="150px"
                      large
                      color="primary"
                      @click="AddSubSector"
                    >
                      اضافة
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  props: ["visible"],
  data() {
    return {
      valid: true,
      allCities: this.$parent.cities,
      allPublicSectors: this.$parent.stats,
      formPublic: {
        name: "",
        email: "",
        location: "",
        city: "",
        logo: null,
      },
      formRule: [(v) => !!v || "حقل الادخال مطلوب"],
      loadingDialog: false,
      formSub: {
        name: "",
        public_sector: "",
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    getDate(date) {
      return date;
    },

    AddPublicSector() {
      if (this.$refs.formPublic.validate()) {
        this.loadingDialog = true;
        const formData = new FormData();
        formData.append("name", this.formPublic.name);
        formData.append("governorate_id", this.formPublic.city.id);
        formData.append("location", this.formPublic.location);
        formData.append("email", this.formPublic.email);
        formData.append("img", this.formPublic.logo);
        axios
          .post("auth/PublicSector/create", formData)
          .then(() => {
            alert("تم الاضافة بنجاح");
            this.loadingDialog = false;
          })
          .catch(() => {
            this.loadingDialog = false;
          });
      }
    },

    AddSubSector() {
      if (this.$refs.formSub.validate()) {
        this.loadingDialog = true;
        const formData = new FormData();
        formData.append("name", this.formSub.name);
        formData.append("public_sector_id", this.formSub.public_sector.id);
        axios
          .post("auth/SubSector/create", formData)
          .then(() => {
            alert("تم الاضافة بنجاح");
            this.loadingDialog = false;
          })
          .catch(() => {
            this.loadingDialog = false;
          });
      }
    },
  },
};
</script>
