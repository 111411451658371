<template>
  <v-dialog v-model="show" max-width="500px">
    <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark class="pa-4">
        <v-card-text>
          يرجى الانتظار...
          <v-progress-linear
            indeterminate
            color="accent"
            class="mt-4"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card>
      <v-card-title class="justify-center">
        <div class="text-h5 text-center">أضافة مدينة صناعية</div>
        <v-spacer></v-spacer>
        <v-btn icon color="primary" @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  return-object
                  :items="allCities"
                  item-text="name"
                  label="المحافظة"
                  outlined
                  :rules="formRule"
                  v-model="form.governerate"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  required
                  outlined
                  v-model="form.area"
                  :rules="formRule"
                  label="المساحة"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  required
                  outlined
                  v-model="form.estimated_cost"
                  :rules="formRule"
                  label="القيمة التخمينية للانشاء"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  required
                  outlined
                  v-model="form.duration"
                  :rules="formRule"
                  label="المدة"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          elevation="2"
          width="150px"
          large
          color="primary"
          @click="AddCity"
        >
          اضافة
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  props: ["visible"],
  data() {
    return {
      valid: true,
      allCities: this.$parent.allCities,
      allTypes: ["غذائية", "معدنية", "انشائية", "نسيجية", "كيمياوية"],
      form: {
        governerate: "",
        area: "",
        estimated_cost: "",
        duration: "حسب الجدول الزمني المقدم في دراسات الجدوى",
      },
      formRule: [(v) => !!v || "حقل الادخال مطلوب"],
      loadingDialog: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    getDate(date) {
      return date;
    },
    AddCity() {
      if (this.$refs.form.validate()) {
        this.loadingDialog = true;
        const formData = new FormData();
        formData.append("governorate_id", this.form.governerate.id);
        formData.append("area", this.form.area);
        formData.append("estimated_cost", this.form.estimated_cost);
        formData.append("duration", this.form.duration);
        axios
          .post("auth/Cities/create", formData)
          .then(() => {
            alert("تم الاضافة بنجاح");
            this.loadingDialog = false;
          })
          .catch(() => {
            this.loadingDialog = false;
          });
      }
    },
  },
};
</script>
