<script>
export default {
  props: {
    lat: { type: Number, required: true },
    lng: { type: Number, required: true },
    name: { type: String, required: true },
    index: null,
  },
  data: () => ({
    marker: null,
    infoWindow: null,
  }),
  mounted() {
    this.$parent.getMap((map) => {
      this.marker = new window.google.maps.Marker({
        position: { lat: this.lat - 0.1, lng: this.lng },
        map: map,
        icon: {
             url: "https://gdid-platform.com/assets/neon.svg",
             scaledSize: new window.google.maps.Size(30, 30), // scaled size
             labelOrigin: { x: 13, y: 0}
        },
        title: this.name,
        label:{
          text:this.name,
          color:'#FFFFFF',
          fontSize: '16px',
          fontWeight: 'Bold'
        }
      });
    });
    window.google.maps.event.addListener(this.marker, "click", () => {
      this.$parent.showMarkerDialog(this.$parent.cities[this.index]);
    });
  },
  beforeDestroy() {
    this.marker.setMap(null);
    window.google.maps.event.clearInstanceListeners(this.marker);
  },
  render() {
    return null;
  },
};
</script>
