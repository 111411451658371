<template>
  <div>
    <v-dialog v-model="show"  min-width="850px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ selectedCity.name }}</span>
          <v-spacer></v-spacer>
          <download-excel
            :fields="{
              اسم_الشركة: 'name',
              المحافظة: 'governorate.name',
              العنوان: 'location',
              سنة_التأسيس: 'year_established',
              المعامل: 'factories',
              نسبة_مساهمة_القطاع_العام: 'public_share',
              نسبة_مساهمة_القطاع_الخاص: 'private_share',
              الهاتف_الرئيسي: 'phone',
              البريد_الالكتروني: 'email',
            }"
            :name="selectedCity.name + '- شركات القطاع المختلط'"
            :data="items"
          >
            <v-btn icon color="primary">
              <v-icon>mdi-file-download</v-icon>
            </v-btn>
          </download-excel>

          <v-btn icon color="primary" @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          class="pb-2"
          :headers="headers"
          :items="items"
          hide-default-footer
        >
                        <template v-slot:[`item.public_share`]="{ item }">
                  <p>{{item.public_share*100}}% </p>
                </template>
                <template v-slot:[`item.private_share`]="{ item }">
                  <p>{{item.private_share*100}}% </p>
                </template>

        </v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["visible", "selectedCity", "selectedCitySharedProjects"],
  components: {},
  data() {
    return {
      headers: [
        {
          text: "اسم الشركة",
          align: "center",
          sortable: false,
          value: "name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المحافظة",
          align: "center",
          sortable: false,
          value: "governorate.name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "العنوان",
          align: "center",
          sortable: false,
          value: "location",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "سنة التأسيس",
          align: "center",
          sortable: false,
          value: "year_established",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المعامل",
          align: "center",
          sortable: false,
          value: "factories",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "نسبة مساهمة القطاع العام",
          align: "center",
          sortable: false,
          value: "public_share",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "نسبة مساهمة القطاع الخاص",
          align: "center",
          sortable: false,
          value: "private_share",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "الهاتف الرئيسي",
          align: "center",
          sortable: false,
          value: "phone",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "البريد الالكتروني",
          align: "center",
          sortable: false,
          value: "email",
          class: "primary white--text ",
          width: 150,
        },
      ],
      items: this.selectedCitySharedProjects,
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    show: function () {
      this.items = this.selectedCitySharedProjects;
    },
  },
  methods: {},
};
</script>
