<template>
  <v-dialog v-model="show">
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="بحث"
          single-line
          hide-details
          class="pa-0 ma-0"
        ></v-text-field>
        <download-excel
          :fields="{
            رقم_الاجازة: 'license_num',
            اسم_المشروع: 'project_name',
            المنطوق: 'foundation_license_type',
            عدد_الطاقة_الانتاجية: 'production_capacity',
            وحدة_القياس: 'production_unit',
            المادة_المنتجة: 'material',
            حالة_المشروع: 'status',
          }"
          :data="selectedProjectList"
          :name="text"
        >
          <v-btn icon color="primary">
            <v-icon>mdi-file-download</v-icon>
          </v-btn>
        </download-excel>

        <v-btn icon color="primary" @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        class="pb-2"
        fixed-header
        :headers="headers"
        :items="selectedProjectList"
        :search="search"
        disable-sort
      >
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["visible", "selectedProjectList", "selectedProjectIsComplete","text"],
  data() {
    return {
      search: "",
      headers: [
        {
          text: "رقم الاجازة",
          align: "center",
          value: "license_num",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "اسم المشروع",
          align: "center",
          value: "project_name",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "نوع الصناعة",
          align: "center",
          value: "industry_type",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المادة المنتجة",
          align: "center",
          value: "material",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "المنطوق",
          align: "center",
          value: "foundation_license_type",
          class: "primary white--text ",
          width: 150,
        },
        {
          text: "عدد الطاقة الانتاجية",
          align: "center",
          value: "production_capacity",
          class: "primary white--text",
          width: 150,
        },
        {
          text: "وحدة القياس",
          align: "center",
          value: "production_unit",
          class: "primary white--text",
          width: 150,
        },
        {
          text: "حالة المشروع",
          align: "center",
          value: "status",
          class: "primary white--text ",
          width: 150,
        },
      ],
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    closeProjectDialog() {
      console.log(this.show);
    },
  },
};
</script>

