var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"min-width":"850px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.selectedCity.name))]),_c('v-spacer'),_c('download-excel',{attrs:{"fields":{
            اسم_الشركة: 'name',
            المحافظة: 'governorate.name',
            العنوان: 'location',
            سنة_التأسيس: 'year_established',
            المعامل: 'factories',
            نسبة_مساهمة_القطاع_العام: 'public_share',
            نسبة_مساهمة_القطاع_الخاص: 'private_share',
            الهاتف_الرئيسي: 'phone',
            البريد_الالكتروني: 'email',
          },"name":_vm.selectedCity.name + '- شركات القطاع المختلط',"data":_vm.items}},[_c('v-btn',{attrs:{"icon":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-file-download")])],1)],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-data-table',{staticClass:"pb-2",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.public_share",fn:function(ref){
          var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.public_share*100)+"% ")])]}},{key:"item.private_share",fn:function(ref){
          var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.private_share*100)+"% ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }