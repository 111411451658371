<template>
  <div class="wrapper">
    <div class="stats" v-if="cities">
      <v-card
        :width="showStats ? 350 : 150"
        min-height="50"
        class="mr-4 rounded-1"
        color="rgb(255, 255, 255, 0.8)"
      >
        <v-card-actions>
          <p class="black--text ma-0 ml-4">الاحصائيات</p>
          <v-spacer></v-spacer>
          <v-btn icon @click="showStats = !showStats" color="black">
            <v-icon>{{
              showStats ? "mdi-chevron-left" : "mdi-chevron-right"
            }}</v-icon>
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <v-simple-table v-show="showStats" height="60vh" class="sim-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">المحافظة</th>
                  <th class="text-center">المساحة</th>
                  <th class="text-center">القيمية التخمينية للانشاء</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in cities"
                  :key="index"
                  class="text-right"
                  :class="itemRowBackground(item, index)"
                >
                  <td>{{ item.governorate.name }}</td>
                  <td>{{ item.area }}</td>
                  <td>{{ item.estimated_cost }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expand-transition>
      </v-card>
    </div>
    <div id="map" ref="map">
      <MapMarker
        :v-if="cities"
        v-for="(city, index) in cities"
        :key="index"
        :lat="getLat(city)"
        :lng="getLng(city)"
        :index="index"
        :name="city.governorate.name"
      />
      <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
        <v-card color="primary" dark class="pa-4">
          <v-card-text>
            يرجى الانتظار...
            <v-progress-linear
              indeterminate
              color="accent"
              class="mt-4"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <IndustrialCityDialog
        v-if="selectedCity"
        :visible="selectedCity"
        :selectedCity="selectedCity"
        @close="cityDialog = false"
      />
      <AddIndustrialCityDialog
        v-if="addCityDialog"
        :visible="addCityDialog"
        @close="addCityDialog = false"
      />
      <ImportIndustrialCityDialog
        v-if="importProjectDialog"
        :visible="importProjectDialog"
        @close="importProjectDialog = false"
      />
    </div>
    <div class="foo" v-if="this.user.type != `User`">
      <v-speed-dial
        v-model="fab"
        top
        direction="top"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-plus-circle </v-icon>
          </v-btn>
        </template>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              v-bind="attrs"
              v-on="on"
              color="accent"
              @click="AddCity"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>اضافة مدينة صناعية</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              v-bind="attrs"
              v-on="on"
              color="primary"
              @click="ImportProject"
            >
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
          </template>
          <span>اضافة Excel</span>
        </v-tooltip>
      </v-speed-dial>
    </div>
  </div>
</template>
<script>
import MapMarker from "@/components/MapMarker.vue";
import AddIndustrialCityDialog from "@/components/industrial_cities/AddIndustrialCityDialog.vue";
import IndustrialCityDialog from "@/components/industrial_cities/IndustrialCityDialog.vue";
import ImportIndustrialCityDialog from "@/components/industrial_cities/ImportIndustrialCityDialog.vue";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "MapView",
  data() {
    return {
      map: null,
      cities: [],
      allCities: [],
      selectedCity: null,
      cityDialog: false,
      loadingDialog: false,
      addCityDialog: false,
      importProjectDialog: false,
      stats: "",
      sections: [],
      fab: false,
      showStats: false,
    };
  },
  components: {
    MapMarker,
    IndustrialCityDialog,
    AddIndustrialCityDialog,
    ImportIndustrialCityDialog,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  mounted() {
    this.initMap();
    this.fetchData();
  },
  methods: {
    initMap() {
      try {
        this.map = new window.google.maps.Map(this.$refs["map"], {
          center: { lat: 33.4039313093639, lng: 44.14819337500002 },
          zoom: 7,
          disableDefaultUI: true,
          zoomControl: true,
          styles: [
            {
              elementType: "geometry",
              stylers: [
                {
                  color: "#111e36",
                },
              ],
            },
            {
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "simplified",
                },
              ],
            },
            {
              elementType: "labels",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "administrative",
              elementType: "geometry",
              stylers: [
                {
                  color: "#ffffff",
                },
              ],
            },
            {
              featureType: "administrative.country",
              elementType: "labels.text.fill",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "administrative.land_parcel",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "administrative.locality",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#bdbdbd",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#ffffff",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [
                {
                  color: "#181818",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#1b1b1b",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry.fill",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "geometry",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road.highway.controlled_access",
              elementType: "geometry",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  color: "#0f7099",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#3d3d3d",
                },
              ],
            },
          ],
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fetchData() {
      this.loadingDialog = true;
      let statsCall = axios.post(`Cities/getList`);
      let citiesCall = axios.post(`Governorates/getList`);
      await axios
        .all([statsCall, citiesCall])
        .then(
          axios.spread((...responses) => {
            this.cities = responses[0].data.items;
            this.allCities = responses[1].data.items;
            this.loadingDialog = false;
          })
        )
        .catch(() => {
          this.loadingDialog = false;
        });
    },
    fetchCity(city) {
      const formData = new FormData();
      formData.append("governorate_id", city.governorate.id);
      axios
        .post("Cities/getList", formData)
        .then((res) => {
          this.selectedCity = res.data.items;
          this.loadingDialog = false;
        })
        .catch(() => {
          this.loadingDialog = false;
        });
    },
    AddCity() {
      this.addCityDialog = true;
    },
    ImportProject() {
      this.importProjectDialog = true;
    },
    getLat(city) {
      var position = city.governorate.coordinates;
      var latlng = position.split(",");
      var latitude = parseFloat(latlng[0]);
      return latitude;
    },
    getLng(city) {
      var position = city.governorate.coordinates;
      var latlng = position.split(",");
      var longitude = parseFloat(latlng[1]);
      return longitude;
    },
    getMap(callback) {
      let vm = this;
      function checkForMap() {
        if (vm.map) callback(vm.map);
        else setTimeout(checkForMap, 200);
      }
      checkForMap();
    },
    async showMarkerDialog(city) {
      this.sleep(200);
      this.loadingDialog = true;
      this.fetchCity(city);
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    itemRowBackground: function(item, index) {
      console.log(item);
      if (index % 2 == 0) {
        return "style-1";
      } else {
        return "style-2";
      }
    },
  },
};
</script>
<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
}
.wrapper,
.wrapper #map {
  width: 100%;
  height: 100%;
}

.wrapper .stats {
  position: absolute;
  z-index: 1;
  top: 16px;
  left: 22px;
}
.wrapper .header img {
  width: 100%;
}
.wrapper .foo {
  height: 55px;
  position: absolute;
  z-index: 1;
  bottom: 20%;
  right: 0.3%;
}
.sim-table {
  background: none;
  color: black;
}
.sim-table tr:hover {
  background-color: #2a2e35 !important;
  color: white;
}
.style-1 td {
  background-color: rgba(42, 46, 53, 0.8);
  color: white;
  font-size: 1.1em !important;
}
.style-2 td {
  background-color: rgba(42, 46, 53, 0.6);
  color: white;
  font-size: 1.1em !important;
}
</style>
