var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"650px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.selectedCity.name))]),_c('v-spacer'),_c('download-excel',{attrs:{"fields":{
            المجموع: 'totlaCount',
            النسيجية: 'textile',
            الكيمياوية: 'chemical',
            الانشائية: 'construction',
            المعدنية: 'metal',
            الغذائية: 'food',
            حالة_المشروع: 'status',
          },"name":_vm.selectedCity.name + '- شركات القطاع الخاص',"data":_vm.items}},[_c('v-btn',{attrs:{"icon":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-file-download")])],1)],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-data-table',{staticClass:"pb-2 city-table",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
          var headers = ref.headers;
return [_c('tr',_vm._l((headers),function(header,ind){return _c('td',{key:ind,staticClass:"text-center",on:{"click":function($event){return _vm.handleClick(header.text, item.status)}}},[_vm._v(" "+_vm._s(item[header.value])+" ")])}),0)]}}])}),_c('div',{staticClass:"text-center pb-4"},[_vm._v(" اضغط على العدد المطلوب لاستعراض تفاصيل اكثر ")])],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}},[_c('v-card',{staticClass:"pa-4",attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" يرجى الانتظار... "),_c('v-progress-linear',{staticClass:"mt-4",attrs:{"indeterminate":"","color":"accent"}})],1)],1)],1),(_vm.selectedProjectList)?_c('PrivateProjectDialog',{attrs:{"visible":_vm.projectDialog,"selectedProjectList":_vm.selectedProjectList,"selectedProjectIsComplete":_vm.selectedProjectIsComplete,"text":_vm.excelText},on:{"close":function($event){_vm.projectDialog = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }