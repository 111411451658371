<template>
  <div>
    <v-dialog v-model="show" width="650px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ selectedCity[0].governorate.name }}</span>
          <v-spacer></v-spacer>
          <download-excel
            :fields="{
              المساحة: 'area',
              القيمة_التخمينية_للانشاء: 'estimated_cost',
              المدة: 'duration',
            }"
            :name="selectedCity[0].governorate.name + '- المدن الصناعية'"
            :data="items"
          >
            <v-btn icon color="primary">
              <v-icon>mdi-file-download</v-icon>
            </v-btn>
          </download-excel>

          <v-btn icon color="primary" @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          class="pb-2 city-table"
          :headers="headers"
          :items="items"
          hide-default-footer
        >
        </v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["visible", "selectedCity"],
  components: {},
  data() {
    return {
      headers: [
        {
          text: "المساحة",
          align: "center",
          sortable: false,
          value: "area",
          class: "primary white--text ",
        },
        {
          text: "القيمة التخمينية للانشاء",
          align: "center",
          sortable: false,
          value: "estimated_cost",
          class: "primary white--text ",
        },
        {
          text: "المدة",
          align: "center",
          sortable: false,
          value: "duration",
          class: "primary white--text ",
        },
      ],
      items: this.selectedCity,
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    show: function () {
      this.items = this.selectedCity;
    },
  },
  methods: {},
};
</script>
<style lang="scss">
.city-table {
  td:hover {
    cursor: pointer;
    background-color: #eee;
  }
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
