<template>
  <v-dialog v-model="show" max-width="700px">
    <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark class="pa-4">
        <v-card-text>
          يرجى الانتظار...
          <v-progress-linear
            indeterminate
            color="accent"
            class="mt-4"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card>
      <v-card-title class="justify-center">
        <div class="text-h5 text-center">أضافة مشروع قطاع خاص</div>
        <v-spacer></v-spacer>
        <v-btn icon color="primary" @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-checkbox
                  v-model="form.project_type"
                  :label="`هل المشروع مكتمل؟`"
                  outlined
                ></v-checkbox>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  required
                  outlined
                  v-model="form.licenceNumber"
                  :rules="formRule"
                  label="رقم  الاجازة"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  required
                  outlined
                  v-model="form.projectName"
                  :rules="formRule"
                  label="أسم المشروع"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  required
                  v-model="form.licenceType"
                  :rules="formRule"
                  outlined
                  label="منطوق أجازة التأسيس"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  required
                  v-model="form.material"
                  :rules="formRule"
                  outlined
                  label="اسم المادة المنتجة"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  required
                  v-model="form.productionCapacity"
                  :rules="formRule"
                  outlined
                  label="الطاقة الانتاجية"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  required
                  v-model="form.productionUnit"
                  :rules="formRule"
                  outlined
                  label="وحدة الطاقة الانتاجية"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  return-object
                  :items="allCities"
                  item-text="name"
                  label="المحافظة"
                  outlined
                  :rules="formRule"
                  v-model="form.city"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  :items="allTypes"
                  label="نوع الصناعة"
                  outlined
                  :rules="formRule"
                  v-model="form.industryType"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  required
                  outlined
                  v-model="form.status"
                  :rules="formRule"
                  label="حالة المشروع"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          elevation="2"
          width="150px"
          large
          color="primary"
          @click="AddProject"
        >
          اضافة
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  props: ["visible"],
  data() {
    return {
      valid: true,
      allCities: this.$parent.cities,
      allTypes: ["غذائية", "معدنية", "انشائية", "نسيجية", "كيمياوية"],
      form: {
        projectName: "",
        licenceNumber: "",
        licenceType: "",
        material: "",
        productionCapacity: "",
        productionUnit: "",
        city: "",
        industryType: "",
        status: "",
        is_complete: false,
        project_type: false,
      },
      formRule: [(v) => !!v || "حقل الادخال مطلوب"],
      loadingDialog: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    getDate(date) {
      return date;
    },
    AddProject() {
      if (this.$refs.form.validate()) {
        this.loadingDialog = true;

        const formData = new FormData();
        formData.append("licence_number", this.form.licenceNumber);
        formData.append("project_name", this.form.projectName);
        formData.append("foundation_license_type", this.form.licenceType);
        formData.append("status", this.form.status);
        formData.append("material", this.form.material);
        formData.append("governorate_id", this.form.city.id);
        formData.append("production_capacity", this.form.productionCapacity);
        formData.append("production_unit", this.form.productionUnit);
        formData.append("industry_type", this.form.industryType);
        if (this.form.project_type) {
          formData.append("is_complete", 1);
        } else {
          formData.append("is_complete", 0);
        }
        axios
          .post("auth/Projects/create", formData)
          .then(() => {
            alert("تم الاضافة بنجاح");
            this.loadingDialog = false;
          })
          .catch(() => {
            this.loadingDialog = false;
          });
      }
    },
  },
};
</script>
