<template>
  <v-dialog v-model="show" max-width="700px" persistent>
    <v-card>
      <v-card-title class="justify-center">
        <div class="text-h5 text-center">أضافة ملف Excel</div>
        <v-spacer></v-spacer>
        <v-btn icon color="primary" @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                هل المشاريع مكتملة؟
                <v-radio-group row v-model="form.is_complete" :rules="formRule">
                  <v-radio label="مكتملة التأسيس" value="1"></v-radio>
                  <v-radio label="تحت التأسيس" value="0"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6" class="align-center justify-center"> </v-col>
              <v-file-input
                label="ملف المشاريع"
                v-model="form.file"
                chips
                :rules="formRule"
                accept=".xlsx, .xls, .csv"
              ></v-file-input>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          elevation="2"
          width="150px"
          large
          color="primary"
          @click="AddProject"
        >
          اضافة
        </v-btn>
      </v-card-actions>
      <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
        <v-card color="primary" dark class="pa-4">
          <v-card-text>
            يرجى الانتظار...
            <v-progress-linear
              :value="progress"
              color="accent"
              class="mt-4"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  props: ["visible"],
  data() {
    return {
      valid: true,
      form: {
        file: null,
        is_complete: "",
      },
      loadingDialog: false,
      progress: null,
      formRule: [(v) => !!v || "حقل الادخال مطلوب"],
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    AddProject() {
      if (this.$refs.form.validate()) {
        const formData = new FormData();
        formData.append("file", this.form.file);
        formData.append("is_complete", this.form.is_complete);
        axios
          .post("auth/Projects/import", formData, {
            onUploadProgress: (uploadEvent) => {
              this.loadingDialog = true;
              this.progress =
                Math.round((uploadEvent.loaded / uploadEvent.total) * 100) - 5;
            },
          })
          .then(() => {
            this.progress = null;
            this.loadingDialog = false;
          })
          .catch(() => {});
      }
    },
  },
};
</script>
