<template>
  <div>
    <v-dialog v-model="show" width="650px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ selectedCity.name }}</span>
          <v-spacer></v-spacer>
          <download-excel
            :fields="{
              المجموع: 'totlaCount',
              النسيجية: 'textile',
              الكيمياوية: 'chemical',
              الانشائية: 'construction',
              المعدنية: 'metal',
              الغذائية: 'food',
              حالة_المشروع: 'status',
            }"
            :name="selectedCity.name + '- شركات القطاع الخاص'"
            :data="items"
          >
            <v-btn icon color="primary">
              <v-icon>mdi-file-download</v-icon>
            </v-btn>
          </download-excel>

          <v-btn icon color="primary" @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          class="pb-2 city-table"
          :headers="headers"
          :items="items"
          hide-default-footer
        >
          <template #item="{ item, headers }">
            <tr>
              <td
                class="text-center"
                v-for="(header, ind) in headers"
                :key="ind"
                @click="handleClick(header.text, item.status)"
              >
                {{ item[header.value] }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-center pb-4">
          اضغط على العدد المطلوب لاستعراض تفاصيل اكثر
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark class="pa-4">
        <v-card-text>
          يرجى الانتظار...
          <v-progress-linear
            indeterminate
            color="accent"
            class="mt-4"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <PrivateProjectDialog
      v-if="selectedProjectList"
      :visible="projectDialog"
      :selectedProjectList="selectedProjectList"
      :selectedProjectIsComplete="selectedProjectIsComplete"
      :text="excelText"
      @close="projectDialog = false"
    />
  </div>
</template>
<script>
import PrivateProjectDialog from "@/components/private_sector/PrivateProjectDialog.vue";
import axios from "axios";

export default {
  props: ["visible", "selectedCityProjects", "selectedCity"],
  components: {
    PrivateProjectDialog,
  },
  data() {
    return {
      headers: [
        {
          text: "حالة المشروع",
          align: "center",
          sortable: false,
          value: "status",
          class: "primary white--text ",
        },
        {
          text: "غذائية",
          align: "center",
          sortable: false,
          value: "food",
          class: "primary white--text ",
        },
        {
          text: "معدنية",
          align: "center",
          sortable: false,
          value: "metal",
          class: "primary white--text ",
        },
        {
          text: "انشائية",
          align: "center",
          sortable: false,
          value: "construction",
          class: "primary white--text ",
        },
        {
          text: "كيمياوية",
          align: "center",
          sortable: false,
          value: "chemical",
          class: "primary white--text ",
        },
        {
          text: "نسيجية",
          align: "center",
          sortable: false,
          value: "textile",
          class: "primary white--text ",
        },
        {
          text: "المجموع",
          align: "center",
          sortable: false,
          value: "totlaCount",
          class: "primary white--text ",
        },
      ],
      items: [
        {
          status: "كاملة التأسيس",
          textile: this.selectedCityProjects.textileCountComplete,
          chemical: this.selectedCityProjects.chemicalCountComplete,
          construction: this.selectedCityProjects.constructionCountComplete,
          metal: this.selectedCityProjects.metalCountComplete,
          food: this.selectedCityProjects.foodCountComplete,
          totlaCount: this.selectedCityProjects.totalComplete,
        },
        {
          status: "تحت التأسيس",
          textile: this.selectedCityProjects.textileCountNotComplete,
          chemical: this.selectedCityProjects.chemicalCountNotComplete,
          construction: this.selectedCityProjects.constructionCountNotComplete,
          metal: this.selectedCityProjects.metalCountNotComplete,
          food: this.selectedCityProjects.foodCountNotComplete,
          totlaCount: this.selectedCityProjects.totalNotComplete,
        },
        {
          status: "المجموع",
          textile:
            this.selectedCityProjects.textileCountNotComplete +
            this.selectedCityProjects.textileCountComplete,
          chemical:
            this.selectedCityProjects.chemicalCountNotComplete +
            this.selectedCityProjects.chemicalCountComplete,
          construction:
            this.selectedCityProjects.constructionCountNotComplete +
            this.selectedCityProjects.constructionCountComplete,
          metal:
            this.selectedCityProjects.metalCountNotComplete +
            this.selectedCityProjects.metalCountComplete,
          food:
            this.selectedCityProjects.foodCountNotComplete +
            this.selectedCityProjects.foodCountComplete,
          totlaCount:
            this.selectedCityProjects.totalNotComplete +
            this.selectedCityProjects.totalComplete,
        },
      ],
      loadingDialog: false,
      selectedProjectList: null,
      selectedProjectIsComplete: null,
      projectDialog: null,
      excelText: "",
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  watch: {
    show: function() {
      this.items = [
        {
          status: "كاملة التأسيس",
          textile: this.selectedCityProjects.textileCountComplete,
          chemical: this.selectedCityProjects.chemicalCountComplete,
          construction: this.selectedCityProjects.constructionCountComplete,
          metal: this.selectedCityProjects.metalCountComplete,
          food: this.selectedCityProjects.foodCountComplete,
          totlaCount: this.selectedCityProjects.totalComplete,
        },
        {
          status: "تحت التأسيس",
          textile: this.selectedCityProjects.textileCountNotComplete,
          chemical: this.selectedCityProjects.chemicalCountNotComplete,
          construction: this.selectedCityProjects.constructionCountNotComplete,
          metal: this.selectedCityProjects.metalCountNotComplete,
          food: this.selectedCityProjects.foodCountNotComplete,
          totlaCount: this.selectedCityProjects.totalNotComplete,
        },
        {
          status: "المجموع",
          textile:
            this.selectedCityProjects.textileCountNotComplete +
            this.selectedCityProjects.textileCountComplete,
          chemical:
            this.selectedCityProjects.chemicalCountNotComplete +
            this.selectedCityProjects.chemicalCountComplete,
          construction:
            this.selectedCityProjects.constructionCountNotComplete +
            this.selectedCityProjects.constructionCountComplete,
          metal:
            this.selectedCityProjects.metalCountNotComplete +
            this.selectedCityProjects.metalCountComplete,
          food:
            this.selectedCityProjects.foodCountNotComplete +
            this.selectedCityProjects.foodCountComplete,
          totlaCount:
            this.selectedCityProjects.totalNotComplete +
            this.selectedCityProjects.totalComplete,
        },
      ];
    },
  },
  methods: {
    getDate(date) {
      return date;
    },
    handleClick(type, status) {
      if (type == "حالة المشروع") {
        return;
      }
      this.loadingDialog = true;
      const formData = new FormData();
      this.selectedProjectIsComplete = null;
      this.excelText = " المشاريع";
      if (status == "كاملة التأسيس") {
        this.selectedProjectIsComplete = 1;
        formData.append("is_complete", this.selectedProjectIsComplete);
        this.excelText += " كاملة التأسيس";
      } else if (status == "تحت التأسيس") {
        this.selectedProjectIsComplete = 0;
        formData.append("is_complete", this.selectedProjectIsComplete);
        this.excelText += " تحت التأسيس";
      } else {
        this.excelText += " الكلية";
      }
      if (type != "المجموع") {
        formData.append("industry_type", type);
        this.excelText += ` ${type}`;
      }
      this.excelText += `-${this.selectedCity.name}`;
      console.log(this.excelText);
      formData.append("governorate_id", this.selectedCity.id);
      axios
        .post("Projects/getList", formData)
        .then((res) => {
          this.loadingDialog = false;
          this.projectDialog = true;
          this.selectedProjectList = res.data.items;
        })
        .catch(() => {
          this.loadingDialog = false;
        });
    },
  },
};
</script>
<style lang="scss">
.city-table {
  td:hover {
    cursor: pointer;
    background-color: #eee;
  }
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
