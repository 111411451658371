<template>
  <v-dialog v-model="show" max-width="650px">
    <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark class="pa-4">
        <v-card-text>
          يرجى الانتظار...
          <v-progress-linear
            indeterminate
            color="accent"
            class="mt-4"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card>
      <v-card-title class="justify-center">
        <div class="text-h5 text-center">أضافة مشروع مختلط</div>
        <v-spacer></v-spacer>
        <v-btn icon color="primary" @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  required
                  outlined
                  v-model="form.name"
                  :rules="formRule"
                  label="الاسم"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-select
                  return-object
                  :items="allCities"
                  item-text="name"
                  label="المحافظة"
                  outlined
                  :rules="formRule"
                  v-model="form.governerate"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  required
                  outlined
                  v-model="form.location"
                  :rules="formRule"
                  label="العنوان"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  required
                  outlined
                  v-model="form.year_established"
                  :rules="formRule"
                  label="سنة التأسيس"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  required
                  outlined
                  v-model="form.factories"
                  :rules="formRule"
                  label="المعامل"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  required
                  outlined
                  v-model="form.public_share"
                  :rules="formRule"
                  label="نسبة مساهمة القطاع العام (%)"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  required
                  outlined
                  v-model="form.private_share"
                  :rules="formRule"
                  label="نسبة مساهمة القطاع الخاص (%)"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  required
                  outlined
                  v-model="form.phone"
                  :rules="formRule"
                  label="الهاتف الرئيسي"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  required
                  outlined
                  v-model="form.email"
                  :rules="formRule"
                  label="البريد الالكتروني"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          elevation="2"
          width="150px"
          large
          color="primary"
          @click="AddCity"
        >
          اضافة
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  props: ["visible"],
  data() {
    return {
      valid: true,
      allCities: this.$parent.cities,
      form: {
        governorate: "",
        name: "",
        location: "",
        year_established: "",
        factories: "",
        public_share: "",
        private_share: "",
        phone: "",
        email: "",
      },
      formRule: [(v) => !!v || "حقل الادخال مطلوب"],
      loadingDialog: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    getDate(date) {
      return date;
    },
    AddCity() {
      if (this.$refs.form.validate()) {
        this.loadingDialog = true;

        const formData = new FormData();
        formData.append("governorate_id", this.form.governerate.id);
        formData.append("name", this.form.name);
        formData.append("location", this.form.location);
        formData.append("year_established", this.form.year_established);
        formData.append("factories", this.form.factories);
        formData.append("public_share", this.form.public_share);
        formData.append("private_share", this.form.private_share);
        formData.append("phone", this.form.phone);
        formData.append("email", this.form.email);
        axios
          .post("auth/SharedCompany/create", formData)
          .then(() => {
            alert("تم الاضافة بنجاح");
            this.loadingDialog = false;
          })
          .catch(() => {
            this.loadingDialog = false;
          });
      }
    },
  },
};
</script>
